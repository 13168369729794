<template>
  <div id="appPincode">
    <img
      id="appPincode__logo"
      src="../../children/assets/images/logo-color.png"
    />
    <div v-if="waitingConfirmation" class="waitingConfirmation">
      <svg
        id="L9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enable-background="new 0 0 0 0"
        xml:space="preserve"
        width="100"
        height="100"
      >
        <path
          fill="#ff4f79"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
      <h1>Waiting for your guardian to confirm your login request...</h1>
      <button class="guardian-login-form__button" @click="cancelWaiting">
        Cancel
      </button>
    </div>
    <div class="guardian-login-form">
      <div class="guardian-login-form__title">
        <h1>Enter your code!</h1>
        <p>
          Enter the four digit code that your Trusted Guardian set for you to
          enter the Sonik Pocket platform.
        </p>
      </div>
      <AppPinInput
        style="margin-bottom: 30px; width: 80%; font-weight: bold"
        @enter="getPin"
      />

      <button
        :disabled="validatePincode"
        type="submit"
        class="guardian-login-form__button"
        @click="sendLogin"
      >
        <span v-if="isBtnLoading"
          ><svg
            id="L9"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xml:space="preserve"
            width="40"
            height="40"
          >
            <path
              fill="#fff"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path></svg></span>
        <span v-else>Enter</span>
      </button>
      <RouterLink
        replace
        to="/child-login"
        class="guardian-login-form__register"
        style="color: #b4b4b4; margin-top: 25px; font-size: 16px"
      >
        <svg
          width="15"
          height="15"
          xmlns="http://www.w3.org/2000/svg"
          style="margin-right: 6px"
          viewBox="0 0 20 20"
          fill="#b4b4b4"
        >
          <path
            fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd"
          />
        </svg>
        Go back
      </RouterLink>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../children/config";
import { mapActions } from "vuex";
import AppPinInput from "../../children/components/AppPinInput";

export default {
  components: {
    AppPinInput,
  },
  data() {
    return {
      childPin: "",
      waitingConfirmation: true,
      isBtnLoading: false,
      childLoginFamilyId: null,
      login: {
        name: "",
        password: "",
      },
    };
  },
  computed: {
    validatePincode() {
      return this.childPin.length !== 4;
    },
  },
  watch: {
    "$route.params"() {
      if (Object.keys(this.$route.params).length <= 0) {
        this.$router.replace("/");
      }
    },
    waitingConfirmation: function () {
      if (this.waitingConfirmation) {
        setInterval(() => {
          this.sendLogin();
        }, 5000);
      }
    },
  },
  created() {
    if (Object.keys(this.$route.params).length <= 0) {
      this.$router.replace("/");
    }
  },
  methods: {
    ...mapActions(["TOAST"]),
    getPin(pin) {
      this.childPin = pin;
    },
    cancelWaiting() {
      this.$router.replace("/");
    },
    sendLogin() {
      this.isBtnLoading = true;
      if (this.childPin && Object.keys(this.$route.params).length > 0) {
        axios
          .post(config.API_HOST + "login/frontend", {
            password: this.childPin,
            family_id: this.$route.params.email,
          })
          .then((response) => {
            if (response.data.statusCode == 200) {
              const { token, userId, family_id } = response.data.data;
              this.childLoginFamilyId = family_id;
              let selectedFamily = this.childLoginFamilyId;
              this.waitingConfirmation = false;

              localStorage.setItem("children_user_id", userId);
              localStorage.setItem("children_token", token);
              localStorage.setItem("children_family_id", selectedFamily);
              localStorage.setItem("user_type", "child");

              location.replace(
                "https://" +
                  window.location.host +
                  `/children?token=${token}&userId=${userId}&family_id_check=${selectedFamily}`
              );

            } else if (
              response.data.statusCode == 203 ||
              response.data.statusCode == 404
            ) {
              this.TOAST(response.data.message);
              this.isBtnLoading = false;
            } else if (response.data.statusCode == 406) {

              this.isBtnLoading = false;
              this.childLoginFamilyId = response.data.data.id;

              localStorage.setItem("password", this.childPin);
              localStorage.setItem("family_id", response.data.data.id);

              this.login.password = this.childPin;
              this.login.name = this.selectedFamilyObject;
              this.waitingConfirmation = true;
            } else {
              this.TOAST(response.data.message);
              this.isBtnLoading = false;
              this.modalType = "childSearchFamily";
            }
          })
          .catch((error) => {
            throw error;
          });
      } else {
        this.modalType = "childSearchFamily";
      }
      this.isBtnLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-size: cover !important;
  position: relative;
}

#appPincode {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__logo {
    width: 70px;
    height: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
  }
}

.guardian-login-form {
  height: 500px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &__input {
    background-color: #f8f8f8;
    width: 80%;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    color: #444 !important;
  }

  &__title {
    margin-bottom: 40px;
    text-align: center;
    color: #7a6159;
    width: 80%;

    h1 {
      font-size: 16px;
    }

    p {
      margin-top: 5px;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &__button {
    width: 80%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    background-color: var(--red);
    margin-top: 10px;
    color: white;
    outline: none;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 15px 40px -3px rgba(54, 213, 242, 0.1),
      0 4px 6px -2px rgba(54, 213, 242, 0.5);
    transition: transform 100ms ease-in-out;

    &:active {
      transform: scale(0.99);
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:focus {
      background-color: var(--red);
    }
  }

  &__register {
    margin-top: 25px;
    text-align: center;
    width: 80%;
    color: #777;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 5px;
    }
  }
}

::placeholder {
  color: #bebebe;
}

.waitingConfirmation {
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  h1 {
    text-align: center;
    color: var(--red);
    font-size: 16px;
    padding: 0 20px;
    line-height: 20px;
  }
}
</style>
